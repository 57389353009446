import { birthday, spiceGirls, jojo } from "../../assets/images";
import { Track, Games } from "./types";

export const games: Games = {
  birthday: {
    name: "Madison's Music Matchup!",
    handle: "birthday",
    artwork: true,
    coverImage: birthday,
    attemptsAllowed: 10,
  },
  jojo: {
    name: "JoJo Siwa",
    handle: "jojo",
    artwork: false,
    coverImage: jojo,
    attemptsAllowed: 7,
  },
  spiceGirls: {
    name: "Spice Girls",
    handle: "spiceGirls",
    artwork: false,
    coverImage: spiceGirls,
    attemptsAllowed: 7,
  },
};

export const tracks: Track[] = [
  {
    name: "Kid in a Candy Store",
    art: "KidinaCandyStore.jpg",
    src: "KidinaCandyStore.mp3",
    bpm: 100.998,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1,
    },
    game: "birthday",
  },
  {
    name: 'Let It Go - From "Frozen"/Soundtrack Version',
    art: "LetItGoFromFrozenSoundtrackVersion.jpg",
    src: "LetItGoFromFrozenSoundtrackVersion.mp3",
    bpm: 136.961,
    attempted: false,
    dance: {
      name: "sway",
      speed: 4.483,
    },
    game: "birthday",
  },
  {
    name: "The Family Madrigal",
    art: "TheFamilyMadrigal.jpg",
    src: "TheFamilyMadrigal.mp3",
    bpm: 141.391,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1,
    },
    game: "birthday",
    startTime: 30.5,
  },
  {
    name: 'When Will My Life Begin? - From "Tangled" / Soundtrack Version',
    art: "WhenWillMyLifeBeginFromTangledSoundtrackVersion.jpg",
    src: "WhenWillMyLifeBeginFromTangledSoundtrackVersion.mp3",
    bpm: 108.066,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.801,
    },
    game: "birthday",
  },
  {
    name: "Nobody Like U",
    art: "NobodyLikeU.jpg",
    src: "NobodyLikeU.mp3",
    bpm: 105.026,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.75,
    },
    game: "birthday",
    startTime: 15,
  },
  {
    name: "A Dream Is a Wish Your Heart Makes",
    art: "ADreamIsaWishYourHeartMakes.jpg",
    src: "ADreamIsaWishYourHeartMakes.mp3",
    bpm: 139.381,
    attempted: false,
    dance: {
      name: "sway",
      speed: 3.5,
    },
    game: "birthday",
  },
  {
    name: "Try Everything",
    art: "TryEverything.jpg",
    src: "TryEverything.mp3",
    bpm: 115.474,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.925,
    },
    game: "birthday",
  },
  {
    name: "Beauty and the Beast",
    art: "BeautyandtheBeast.jpg",
    src: "BeautyandtheBeast.mp3",
    bpm: 90.687,
    attempted: false,
    dance: {
      name: "sway",
      speed: 3,
    },
    game: "birthday",
    startTime: 75,
  },
  {
    name: "Good Vibes",
    art: "GoodVibes.jpg",
    src: "GoodVibes.mp3",
    bpm: 93.972,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.566,
    },
    game: "birthday",
    startTime: 3,
  },
  {
    name: "Into the Unknown",
    art: "IntotheUnknown.jpg",
    src: "IntotheUnknown.mp3",
    bpm: 145.104,
    attempted: false,
    dance: {
      name: "sway",
      speed: 2.418,
    },
    game: "birthday",
    startTime: 15,
  },
  {
    name: "Galway Girl",
    art: "GalwayGirl.jpg",
    src: "GalwayGirl.mp3",
    bpm: 99.943,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.666,
    },
    game: "birthday",
    startTime: 3,
  },
  {
    name: "Together We Stand",
    art: "TogetherWeStand.jpg",
    src: "TogetherWeStand.mp3",
    bpm: 175.722,
    attempted: false,
    dance: {
      name: "sway",
      speed: 2.929,
    },
    game: "birthday",
    startTime: 50,
  },
  {
    name: "Wannabe",
    art: "Wannabe.jpg",
    src: "Wannabe.mp3",
    bpm: 110.008,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.833,
    },
    game: "birthday",
    startTime: 49,
  },
  {
    name: "Can't Stop The Feeling!",
    art: "CantStopTheFeeling.jpg",
    src: "CantStopTheFeeling.mp3",
    bpm: 113.035,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.884,
    },
    game: "birthday",
  },
  {
    name: "Don't Slack (from Trolls World Tour)",
    art: "DontSlackfromTrollsWorldTour.jpg",
    src: "DontSlackfromTrollsWorldTour.mp3",
    bpm: 168.114,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.802,
    },
    game: "birthday",
    startTime: 12,
  },
  {
    name: "This Is Me",
    art: "ThisIsMe.jpg",
    src: "ThisIsMe.mp3",
    bpm: 191.702,
    attempted: false,
    dance: {
      name: "sway",
      speed: 3.195,
    },
    game: "birthday",
    startTime: 7,
  },
  {
    name: 'Dancing In The Dark - From The "Home" Soundtrack',
    art: "DancingInTheDarkFromTheHomeSoundtrack.jpg",
    src: "DancingInTheDarkFromTheHomeSoundtrack.mp3",
    bpm: 115.005,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.917,
    },
    game: "birthday",
  },
  {
    name: "Under the Sea",
    art: "UndertheSea.jpg",
    src: "UndertheSea.mp3",
    bpm: 121.252,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.021,
    },
    game: "birthday",
    startTime: 9,
  },
  {
    name: "How Far I'll Go",
    art: "HowFarIllGo.jpg",
    src: "HowFarIllGo.mp3",
    bpm: 80.119,
    attempted: false,
    dance: {
      name: "sway",
      speed: 3.335,
    },
    game: "birthday",
    startTime: 3,
  },
  {
    name: "Shake It Off",
    art: "ShakeItOff.jpg",
    src: "ShakeItOff.mp3",
    bpm: 160.015,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.33,
    },
    game: "birthday",
  },
  {
    name: "Wannabe",
    art: "Wannabe.jpg",
    src: "Wannabe.mp3",
    bpm: 110.008,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.833,
    },
    game: "spiceGirls",
    startTime: 49,
  },
  {
    name: "Stop",
    art: "Stop.jpg",
    src: "Stop.mp3",
    bpm: 130.13,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.169,
    },
    game: "spiceGirls",
    startTime: 7,
  },
  {
    name: "Goodbye",
    art: "Goodbye.jpg",
    src: "Goodbye.mp3",
    bpm: 136.019,
    attempted: false,
    dance: {
      name: "sway",
      speed: 2.267,
    },
    game: "spiceGirls",
    startTime: 9,
  },
  {
    name: "Viva Forever",
    art: "VivaForever.jpg",
    src: "VivaForever.mp3",
    bpm: 168.787,
    attempted: false,
    dance: {
      name: "sway",
      speed: 2.813,
    },
    game: "spiceGirls",
  },
  {
    name: "2 Become 1",
    art: "2Become1.jpg",
    src: "2Become1.mp3",
    bpm: 143.966,
    attempted: false,
    dance: {
      name: "sway",
      speed: 2.399,
    },
    game: "spiceGirls",
  },
  {
    name: "Spice Up Your Life",
    art: "SpiceUpYourLife.jpg",
    src: "SpiceUpYourLife.mp3",
    bpm: 124.016,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.067,
    },
    game: "spiceGirls",
    startTime: 18
  },
  {
    name: "Holler",
    art: "Holler.jpg",
    src: "Holler.mp3",
    bpm: 109.998,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.833,
    },
    game: "spiceGirls",
  },
  {
    name: "Say You'll Be There",
    art: "SayYoullBeThere.jpg",
    src: "SayYoullBeThere.mp3",
    bpm: 107.02,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.784,
    },
    game: "spiceGirls",
    startTime: 14
  },
  {
    name: "Let Love Lead The Way",
    art: "LetLoveLeadTheWay.jpg",
    src: "LetLoveLeadTheWay.mp3",
    bpm: 116.029,
    attempted: false,
    dance: {
      name: "sway",
      speed: 3,
    },
    game: "spiceGirls",
    startTime: 3,
  },
  {
    name: "Step To Me",
    art: "StepToMe7Mix.jpg",
    src: "StepToMe7Mix.mp3",
    bpm: 102.974,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.716,
    },
    game: "spiceGirls",
  },
  {
    name: "Who Do You Think You Are",
    art: "WhoDoYouThinkYouAre.jpg",
    src: "WhoDoYouThinkYouAre.mp3",
    bpm: 118.002,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.967,
    },
    game: "spiceGirls",
  },
  {
    name: "Too Much",
    art: "TooMuch.jpg",
    src: "TooMuch.mp3",
    bpm: 80.14,
    attempted: false,
    dance: {
      name: "sway",
      speed: 3.6,
    },
    game: "spiceGirls",
  },
  {
    name: "Headlines",
    art: "HeadlinesFriendshipNeverEndsRadioVersion.jpg",
    src: "HeadlinesFriendshipNeverEndsRadioVersion.mp3",
    bpm: 143.931,
    attempted: false,
    dance: {
      name: "sway",
      speed: 2.399,
    },
    game: "spiceGirls",
    startTime: 22
  },
  {
    name: "Hold the Drama",
    art: "HoldtheDrama.jpg",
    src: "HoldtheDrama.mp3",
    bpm: 122.047,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.034,
    },
    game: "jojo",
  },
  {
    name: "Every Girl's a Super Girl",
    art: "EveryGirlsaSuperGirl.jpg",
    src: "EveryGirlsaSuperGirl.mp3",
    bpm: 127.991,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.133,
    },
    game: "jojo",
  },
  {
    name: "High Top Shoes",
    art: "HighTopShoes.jpg",
    src: "HighTopShoes.mp3",
    bpm: 140.069,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.334,
    },
    game: "jojo",
  },
  {
    name: "Boomerang",
    art: "Boomerang.jpg",
    src: "Boomerang.mp3",
    bpm: 115.013,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.917,
    },
    game: "jojo",
  },
  {
    name: "Kid in a Candy Store",
    art: "KidinaCandyStore.jpg",
    src: "KidinaCandyStore.mp3",
    bpm: 100.998,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.683,
    },
    game: "jojo",
  },
  {
    name: "Worldwide Party",
    art: "WorldwidePartyRemix.jpg",
    src: "WorldwidePartyRemix.mp3",
    bpm: 91.99,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 1.533,
    },
    game: "jojo",
  },
  {
    name: "It's Time to Celebrate",
    art: "ItsTimetoCelebrate.jpg",
    src: "ItsTimetoCelebrate.mp3",
    bpm: 140.012,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.334,
    },
    game: "jojo",
  },
  {
    name: "Number 1 You",
    art: "#1U.jpg",
    src: "1U.mp3",
    bpm: 160.023,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.667,
    },
    game: "jojo",
    startTime: 8
  },
  {
    name: "Bop!",
    art: "Bop!.jpg",
    src: "Bop.mp3",
    bpm: 160.007,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.667,
    },
    game: "jojo",
  },
  {
    name: "D.R.E.A.M.",
    art: "DREAM.jpg",
    src: "DREAM.mp3",
    bpm: 154.907,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 2.582,
    },
    game: "jojo",
  },
  {
    name: "Everyday Popstars",
    art: "EverydayPopstars.jpg",
    src: "EverydayPopstars.mp3",
    bpm: 183.805,
    attempted: false,
    dance: {
      name: "wiggle",
      speed: 3.063,
    },
    game: "jojo",
  },
];
