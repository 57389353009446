import './Player.css';
import { useGameContext } from '../../GameProvider';
import { cdnUrl } from '../../types';


export function Player() {
  const {audioRef, currentChallenge} = useGameContext();
  const track = currentChallenge?.correctTrack;

  const handleLoadedMetadata = () => {
    if (!audioRef?.current) return;
    if (track?.startTime) {
      audioRef.current.currentTime = track.startTime;
    }
  };

  const graduallyIncreaseVolume = () => {
    const duration = 1000;
    const interval = 10;

    const startVolume = 0;
    const endVolume = 1;
    let currentVolume = startVolume;
    const volumeIncrement = (endVolume - startVolume) / (duration / interval);

    const volumeRamp = setInterval(() => {
      currentVolume += volumeIncrement;
      if (currentVolume >= endVolume) {
        clearInterval(volumeRamp);
        currentVolume = endVolume; // Ensure the volume reaches the exact end value
      }
      if(audioRef?.current){
        audioRef.current.volume = currentVolume;
      }
    }, interval);
  }


  return (
    <audio
      ref={audioRef}
      src={track && cdnUrl(track?.src)}
      onLoadedMetadata={handleLoadedMetadata}
      onPlay={graduallyIncreaseVolume}
      autoPlay
      crossOrigin="anonymous"
    />
  );
}
