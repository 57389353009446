import React from "react";

import "./PlayAgain.css";
import { GameState } from "../../../../types";
import { useGameContext } from "../../../../GameProvider";

export function PlayAgain() {
  const {gameState} = useGameContext();

  const message = gameState === GameState.GameOver ? "Great Job!" : "Welcome Back!";

  return (
    <div className="PlayAgain">
      <h1>{message}</h1>
      <p>Select from the games below to play again with new songs!</p>
    </div>
  );
}
