import React from 'react';

import './GameControls.css';
import { useGameContext } from '../../GameProvider';
import { GameState } from '../../types';
import { birthday } from '../../../../assets/images';

export function GameControls() {
  const {newGame, welcome, gameState} = useGameContext();

  const buttonMarkup = () => {
    switch (gameState) {
      case GameState.Start:
        return <button onClick={welcome} className='NewGameButton'>Start</button>;
        case GameState.Welcome:
        return <button onClick={() => newGame('birthday')} className='NewGameButton'>Play</button>;
      default:
        return
    }
  };

  return <div className="GameControls">
    {GameState.Start === gameState && <img src={birthday} alt="birthday" />}
    {buttonMarkup()}
    </div>
}
