import React from "react";
import Confetti from "react-confetti";
import "./Game.css";
import {
  Scoreboard,
  Challenge,
  GameControls,
  Visualizer,
  Selector,
} from "./components";
import { useGameContext } from "./GameProvider";
import { GameState } from "./types";
import { Player } from "./components/Player";
import { ImagePreloader } from "../ImagePreloader";
import { tracks } from "./config";

export function Game() {
  const {gameState, currentChallenge} = useGameContext();
  const {name, speed} = currentChallenge?.correctTrack.dance || {name: "wiggle", speed: 4};
  const images = tracks.filter((track) => track.game === "birthday").map((track) => track.art);

  const classes= `Game Game--${GameState[gameState]}`;
  const style = {"--danceSpeed": `${speed}s`, "--danceName": name} as React.CSSProperties;
  return (
    <div className={classes} style={style}>
      <ImagePreloader imageUrls={images} />
      <Scoreboard />
      <Challenge />
      <GameControls />
      <Selector />
      <Player />
      <Visualizer />
      {gameState === GameState.GameOver && <Confetti />}
    </div>
  );
}
