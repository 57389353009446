import { useEffect } from 'react';
import { cdnUrl } from '../Game/types';


export interface ImagePreloaderProps {
  imageUrls: string[];
}

export const ImagePreloader = ({ imageUrls }: ImagePreloaderProps) => {
  useEffect(() => {
    // Preload images when the component mounts
    imageUrls.forEach(url => {
      const img = new Image();
      img.src = cdnUrl(url);
    });
  }, [imageUrls]);

  return null; // Since this component only preloads images, it doesn't need to render anything
};
