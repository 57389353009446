import React, {
  useContext,
  useState,
  createContext,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import {
  Challenge,
  Track,
  GameState,
  cdnUrl,
  ChallengeState,
  Game,
} from "./types";
import { games, tracks } from "./config";
import { Wave } from "@foobar404/wave";
import { correct, incorrect } from "../../assets/sounds";

interface GameProviderContext {
  score: number;
  attempts: number;
  bestScore: number;
  currentChallenge?: Challenge;
  checkChallenge: (answer: Track) => void;
  audioRef?: React.RefObject<HTMLAudioElement>;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
  gameState: GameState;
  newGame: (gameHandle: string) => void;
  welcome: () => void;
  currentGame?: Game;
}

const GameContext = createContext<GameProviderContext>({
  score: 0,
  attempts: 0,
  bestScore: 0,
  currentChallenge: undefined,
  checkChallenge: () => {
    throw new Error("checkChallenge not Implemented");
  },
  newGame: (gameHandle: string) => {
    throw new Error("newGame not Implemented");
  },
  welcome: () => {
    throw new Error("welcome not Implemented");
  },
  audioRef: undefined,
  canvasRef: undefined,
  gameState: GameState.Start,
  currentGame: undefined,
});

interface ProviderProps {
  children?: React.ReactNode;
}

export function GameProvider({ children }: ProviderProps) {
  const [score, setScore] = useState(0);
  const [currentChallenge, setCurrentChallenge] = useState<
    Challenge | undefined
  >(undefined);
  const [attempts, setAttempts] = useState(0);
  const [bestScore, setBestScore] = useState<number>(0);
  const [gameState, setGameState] = useState<GameState>(GameState.Start);
  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [wave, setWave] = useState<Wave | null>(null);
  const isMountedRef = useRef(false);
  const [game, setGame] = useState("birthday");
  const [gameTracks, setGameTracks] = useState<Track[]>([]);
  const currentGame = games[game];
  const attemptsAllowed = currentGame?.attemptsAllowed || 10;

  const resetBestScore = useCallback((gameHandle: string) => {
    const savedBestScore = localStorage.getItem(`bestScore-${gameHandle}`);
    if (savedBestScore !== null) {
      return setBestScore(parseInt(savedBestScore));
    }
    setBestScore(0);
  }, []);

  useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [game]);

  const checkSawWelcome = useCallback(() => {
    const sawWelcome = localStorage.getItem(`sawWelcome`);
    if (sawWelcome !== null) {
      setGameState(GameState.Retry);
    } else {
      setGameState(GameState.Welcome);
    }
  }, [setGameState]);

  useEffect(() => {
    if (
      !isMountedRef.current &&
      audioRef.current &&
      canvasRef.current &&
      !wave
    ) {
      setWave(new Wave(audioRef.current, canvasRef.current));
      canvasRef.current.style.width = "100%";
      canvasRef.current.style.height = "100%";
      canvasRef.current.style.minHeight = "30vh";
      canvasRef.current.width = canvasRef.current.offsetWidth;
      canvasRef.current.height = canvasRef.current.offsetHeight;
      isMountedRef.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef.current, canvasRef.current, wave]);

  const playWelcome = useCallback(() => {
    if (!audioRef.current) return;
    audioRef.current.src = cdnUrl("welcome.mp3");
    audioRef.current.play();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef.current]);

  const welcome = useCallback(() => {
    checkSawWelcome();
    localStorage.setItem(`sawWelcome`, "true");
    playWelcome();
  }, [playWelcome, checkSawWelcome]);

  useEffect(() => {
    if (!wave) return;
    wave.clearAnimations();
    wave.addAnimation(
      new wave.animations.Wave({
        lineColor: "white",
        lineWidth: 10,
        fillColor: { gradient: ["#FF9A8B", "#FF6A88", "#FF99AC"] },
        mirroredX: true,
        count: 5,
        rounded: true,
        frequencyBand: "base",
      })
    );
    wave.addAnimation(
      new wave.animations.Wave({
        lineColor: "white",
        lineWidth: 10,
        fillColor: { gradient: ["#FA8BFF", "#2BD2FF", "#2BFF88"] },
        mirroredX: true,
        count: 60,
        rounded: true,
      })
    );
    wave.addAnimation(
      new wave.animations.Wave({
        lineColor: "white",
        lineWidth: 10,
        fillColor: { gradient: ["#FBDA61", "#FF5ACD"] },
        mirroredX: true,
        count: 25,
        rounded: true,
        frequencyBand: "highs",
      })
    );
  }, [wave]);

  const randomIndex = useCallback(
    (length: number) => Math.floor(Math.random() * length),
    []
  );

  const loadChallenge = useCallback(() => {
    const remainingTracks = gameTracks.filter((track) => !track.attempted);
    const randomTrack = remainingTracks[randomIndex(remainingTracks.length)];
    randomTrack.attempted = true;

    const incorrectTrack = gameTracks.filter((track) => track !== randomTrack)[
      randomIndex(remainingTracks.length - 1)
    ];

    setCurrentChallenge({
      correctTrack: randomTrack,
      incorrectTrack: incorrectTrack,
      state: ChallengeState.InProgress,
    });
  }, [randomIndex, gameTracks]);

  const delay = useCallback(
    (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),
    []
  );

  const playSoundEffect = (correctAnswer: Boolean) => {
    const soundEffectFile = correctAnswer ? correct : incorrect;
    const audio = new Audio(soundEffectFile);
    audio.play();
  };

  const checkEndGame = useCallback(async () => {
    await delay(3000);
    if (attempts < attemptsAllowed - 1) {
      loadChallenge();
    } else {
      setCurrentChallenge(undefined);
      setGameState(GameState.GameOver);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempts, loadChallenge, setGameState, delay]);

  const checkChallenge = useCallback(
    async (answer: Track) => {
      if (!currentChallenge) return;

      if (currentChallenge.correctTrack === answer) {
        setCurrentChallenge({
          ...currentChallenge,
          state: ChallengeState.Correct,
        });
        playSoundEffect(true);
        const newScore = score + 1;
        setScore(newScore);
        if (newScore > bestScore) {
          setBestScore(newScore);
          localStorage.setItem(`bestScore-${game}`, newScore.toString());
        }
      } else {
        playSoundEffect(false);
        setCurrentChallenge({
          ...currentChallenge,
          state: ChallengeState.Incorrect,
        });
      }

      setAttempts(attempts + 1);
      await checkEndGame();
    },
    [
      score,
      attempts,
      currentChallenge,
      checkEndGame,
      setScore,
      setAttempts,
      bestScore,
      game,
    ]
  );

  const newGame = useCallback(
    (gameHandle: string) => {
      const newGameTracks = tracks
        .filter((track) => track.game === gameHandle)
        .map((track) => {
          return { ...track, attempted: false };
        });

      setGame(gameHandle);
      resetBestScore(gameHandle);
      setGameTracks(newGameTracks);
      setScore(0);
      setAttempts(0);
      setGameState(GameState.Playing);
    },
    [
      setGame,
      setScore,
      setAttempts,
      setGameState,
      setGameTracks,
      resetBestScore,
    ]
  );

  useEffect(() => {
    if (gameTracks.length > 0) {
      loadChallenge();
    }
  }, [gameTracks, loadChallenge]);

  const contextValue = useMemo(
    () => ({
      score,
      attempts,
      newGame,
      bestScore,
      currentChallenge,
      checkChallenge,
      audioRef,
      canvasRef,
      gameState,
      welcome,
      currentGame,
    }),
    [
      score,
      attempts,
      newGame,
      bestScore,
      currentChallenge,
      checkChallenge,
      audioRef,
      canvasRef,
      gameState,
      welcome,
      currentGame,
    ]
  );

  return (
    <GameContext.Provider value={contextValue}>{children}</GameContext.Provider>
  );
}

export function useGameContext() {
  return useContext(GameContext);
}
