import React from "react";

import { useGameContext } from "../../GameProvider";
import "./Selector.css";
import { games } from "../../config";
import { PlayAgain } from "../Challenge/components";
import { GameState } from "../../types";

export function Selector() {
  const { newGame, gameState } = useGameContext();

  if(gameState !== GameState.Retry && gameState !== GameState.GameOver) return null;

  const gameOptions = Object.values(games).map((game) => {
    return (
      <button
        onClick={() => {
          newGame(game.handle);
        }}
        key={game.name}
        className="selector__button"
      >
        <img
          className="selector__image"
          src={game.coverImage}
          alt={game.name}
        />
      </button>
    );
  });

  return (
    <div className="Selector">
      <PlayAgain />
      <div className="Selector-Item">{gameOptions}</div>
    </div>
  );
}
