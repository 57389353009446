import React from 'react';

import './Visualizer.css';
import { useGameContext } from '../../GameProvider';

export function Visualizer() {
  const {canvasRef} = useGameContext();
  return <div className='Visualizer'>
    <canvas ref={canvasRef}></canvas>
  </div>
}
