export interface Track {
  name: string;
  art: string;
  src: string;
  attempted: boolean;
  startTime?: number;
  bpm: number;
  dance: Dance;
  game: string;
}

export enum GameState {
  Welcome,
  Start,
  Playing,
  GameOver,
  Retry
}

export interface Dance {
  name: string;
  speed: number;
}

export type Tracks = Track[];

export enum ChallengeState {
  Correct,
  Incorrect,
  InProgress
}
export interface Challenge {
  correctTrack: Track;
  incorrectTrack: Track;
  state: ChallengeState;
}

export const cdnUrl = (path: string) => {
  return `https://cdn.shopify.com/s/files/1/0258/6440/5052/files/${path}`;
}

export interface Game {
  name: string;
  handle: string;
  artwork: boolean;
  coverImage: string;
  attemptsAllowed: number;
}

export type Games = {
  [key: string]: Game
}
