import React from 'react';

import './Scoreboard.css';
import { useGameContext } from '../../GameProvider';
import { ChallengeState, GameState } from '../../types';

export function Scoreboard() {
  const {score, attempts, bestScore, gameState, currentChallenge} = useGameContext();

  const cssClass = ["scoreboard"];

  if(gameState !== GameState.Playing && gameState !== GameState.GameOver) {
    return null;
  }

  if(gameState === GameState.GameOver) {
    cssClass.push("scoreboard--winner");
  }


  if(currentChallenge?.state === ChallengeState.Correct) {
    cssClass.push("scoreboard--correct");
  }

  if(currentChallenge?.state === ChallengeState.Incorrect) {
    cssClass.push("scoreboard--incorrect");
  }

  return <div className={cssClass.join(' ')}>
    <div className="score score__current">
      Score: <span id="current-score">{score}</span>/{attempts}
    </div>
    <div className="score score__best">
      Best: <span id="best-score">{bestScore}</span>
    </div>
  </div>
}
