import React from "react";

import "./Challenge.css";
import { useGameContext } from "../../GameProvider";
import { Album } from "./components/Album/Album";
import { Welcome } from "./components";
import { ChallengeState, GameState } from "../../types";
import { shuffle } from "lodash";

export function Challenge() {
  const { currentChallenge, gameState } = useGameContext();

  if (gameState === GameState.Welcome) {
    return <Welcome />;
  }

  if (!currentChallenge) {
    return null;
  }

  const currentDance = currentChallenge.correctTrack.dance;

  const tracks = [
    <Album
      key={currentChallenge.correctTrack.name}
      track={currentChallenge.correctTrack}
      dance={currentDance}
    />,
  ];

  if (currentChallenge.state === ChallengeState.InProgress) {
    tracks.push(
      <Album
        key={currentChallenge.incorrectTrack.name}
        track={currentChallenge.incorrectTrack}
        second
        dance={currentDance}
      />
    );
  }

  return <div className="Challenge">{shuffle(tracks)}</div>;
}
