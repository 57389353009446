import React from "react";

import "./Welcome.css";

export function Welcome() {
  return (
    <div className="Welcome">
      <h1>Happy Birthday Madison!</h1>
      <p>
        Oh, wow! Can you believe another year has passed? You're now six years
        old, and we're so excited for you!
      </p>
      <p>
        We're over the moon to celebrate your JoJo Birthday this year. Sadly, we
        won't be able to be there in person, but we're sending heaps of love and
        hope you have a delightful time!
      </p>
      <p>
        This year, we've whipped up a musical guessing game filled
        with some of your favorite songs! Put on your thinking cap and see if
        you can outshine your high score.
      </p>
      <p>Afterward, you can even try the
        advanced games, just for the fun of it!
      </p>
      <p>Lots of Love: Tyler and Matt.</p>
    </div>
  );
}
