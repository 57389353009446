import React from 'react';
import './App.css';
import { GameProvider } from './components/Game/GameProvider';
import { Game } from './components';

function App() {
  return (
   <GameProvider>
    <Game />
   </GameProvider>
  );
}

export default App;
