import React from "react";

import "./Album.css";
import { ChallengeState, Dance, Track, cdnUrl } from "../../../../types";
import { useGameContext } from "../../../../GameProvider";

export interface AlbumProps {
  track: Track;
  second?: boolean;
  dance: Dance;
}

export function Album({ track, second, dance }: AlbumProps) {
  const { checkChallenge, currentGame, currentChallenge } = useGameContext();

  const cssClass = ["Album-art"];

  if (second && dance.name === "wiggle") {
    cssClass.push("Album-art--reverse");
  }

  if (!currentGame) {
    return <></>;
  }
  const albumMarkup = currentGame.artwork ? (
    <img src={cdnUrl(track.art)} className="image" alt={track.name} />
  ) : (
    <div className="Album-art__placeholder">
      <div className="placeholder-overlay">
        <div className="text">{track.name}</div>
      </div>
      <img src={currentGame?.coverImage} className="image" alt={track.name} />
    </div>
  );

  return (
    <div
      className="Album"
      onClick={() => {
        if (currentChallenge?.state !== ChallengeState.InProgress) return;
        checkChallenge(track);
      }}
    >
      <div className={cssClass.join(" ")}>{albumMarkup}</div>
    </div>
  );
}
